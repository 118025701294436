/* eslint-disable @typescript-eslint/no-var-requires,@typescript-eslint/no-unsafe-assignment */

const locizeBackend = require('i18next-locize-backend/cjs');
const locizeLastUsed = require('locize-lastused');
const { initReactI18next } = require('react-i18next');

const locizeSettings = {
  projectId: process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID || '',
  // don't add the api-key in production, used for saveMissing feature
  apiKey:
    process.env.NODE_ENV === 'development'
      ? process.env.NEXT_PUBLIC_LOCIZE_API_KEY
      : undefined,
  referenceLng: 'fi',
  version: process.env.NEXT_PUBLIC_LOCIZE_VERSION || 'latest',
};

const use = [
  locizeBackend,
  locizeLastUsed,
  // Include the init middleware from react-i18next so
  // react-i18next will keep the i18next instance within
  // itself. Since next-i18next re-exports e.g. useTranslation
  // from react-i18next, we need react-i18next to have a reference
  // to the initialized i18next instance. Otherwise the only
  // way for useTranslation to get the initialized i18next instance
  // is through the context provided by the I18nextProvider, which
  // won't work in places outside the app tree. If we include this
  // initializer, useTranslation et al will always have access to
  // the i18next instance regardless of context.
  // NOTE: for this to work, the version of react-i18next installed
  // manually in this project must resolve to the same version as
  // next-i18next is using. We've added a resolutions entry in
  // package.json for this purpose.
  initReactI18next,
];

const mainUrl = new URL(process.env.NEXT_PUBLIC_HOST_URL);
const svUrl = new URL(process.env.NEXT_PUBLIC_HOST_URL_SV);

const domains = () => {
  if (mainUrl.hostname === 'localhost') {
    return [
      {
        domain: `${mainUrl.hostname}:${mainUrl.port}`,
        domainURL: mainUrl.toString().replace(/\/$/, ''),
        defaultLocale: 'fi',
        locales: ['fi', 'en', 'sv'],
        http: true,
      },
    ];
  }

  return [
    {
      domain: mainUrl.hostname,
      domainURL: mainUrl.toString().replace(/\/$/, ''),
      defaultLocale: 'fi',
      locales: ['fi', 'en'],
    },
    {
      domain: svUrl.hostname,
      domainURL: svUrl.toString().replace(/\/$/, ''),
      defaultLocale: 'sv',
      locales: ['sv'],
    },
  ];
};

module.exports = {
  // these are for NextJS i18n routing support
  i18n: {
    defaultLocale: 'fi',
    locales: ['fi', 'en', 'sv'],
    localeDetection: false,
    domains: domains(),
  },
  // the rest (in addition to the above) is used by next-i18next to initialize i18next
  returnNull: false, // also see app/types/i18next.d.ts
  backend: {
    ...locizeSettings,
    allowedAddOrUpdateHosts: ['localhost'],
    onSaved: (lng, ns) => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.info(`Saved ${lng}, ${ns}`);
    },
  },
  locizeLastUsed: {
    ...locizeSettings,
    // debounce interval to send data in milliseconds
    debounceSubmit: 90000,
    // hostnames that are allowed to send last used data
    // please keep those to your local system, staging, test servers (not production)
    allowedHosts: ['localhost'],
  },
  // these translate into separate i18next.use() calls within next-i18next
  use,
  ns: [
    'backoffice',
    'email',
    'form_helptexts',
    'frontoffice',
    'generated_pdfs',
    'hasura_errors',
    'tilavahti',
  ], // the namespaces needs to be listed here, to make sure they got preloaded
  serializeConfig: false, // because of the custom use i18next plugin
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  saveMissing: process.env.NODE_ENV === 'development',
  // debug: true,
};
