import { Session, Role } from 'next-auth';
import { ROLES } from '../auth/utils/roles';

const userHasRole = (session?: Session | null, roles: Role[] = []): boolean =>
  !!session?.user.role && roles.includes(session.user.role);

export const isValidRole = (role?: string): role is Role => {
  return !!(
    role &&
    typeof role === 'string' &&
    ROLES.includes(role.toLowerCase() as Role)
  );
};

export default userHasRole;
