import Websocket from 'ws';
import {
  ApolloClient,
  FieldFunctionOptions,
  FieldPolicy,
  GraphQLRequest,
  split,
  HttpLink,
  InMemoryCache,
  ServerError,
} from '@apollo/client';
import type { NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { useMemo } from 'react';
import { GetServerSidePropsContext } from 'next';
import { getSession } from 'next-auth/react';
import { DefinitionNode, OperationDefinitionNode } from 'graphql';
import {
  getMainDefinition,
  offsetLimitPagination,
  Reference,
} from '@apollo/client/utilities';
import publicQueries from '../graphql/queries/publicQueries.graphql';
import {
  ClientAttributesFragment,
  DiaryListingAttributesFragment,
  GetAllListingsAttributesFragment,
  GetSingleClientQueryVariables,
  HistoryAttributesFragment,
  RealEstateAgencyAttributesFragment,
  RealEstateAgencyProtectedAttributesFragment,
  StrictTypedTypePolicies,
} from '@/generated/graphql';

const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL || '';
const NEXT_PUBLIC_API_URL_WS = process.env.NEXT_PUBLIC_API_URL_WS || '';

if (!NEXT_PUBLIC_API_URL || !NEXT_PUBLIC_API_URL_WS) {
  console.error('NEXT_PUBLIC_API_URL or NEXT_PUBLIC_API_URL_WS env missing!');
}

const isOperationDefinition = (
  node: DefinitionNode,
): node is OperationDefinitionNode =>
  node && node.kind === 'OperationDefinition';

const publicOperations = publicQueries.definitions
  // workaround for ts not being able to narrow types using `filter`:
  // extract the operation name in a map and then just filter using Boolean and
  // disregard the resulting flawed type (type includes null and undefined even
  // though they're not possible values)
  .map((node) => (isOperationDefinition(node) ? node.name?.value : null))
  .filter(Boolean);

const isAlwaysAnonymousOperation = (gqlRequest: GraphQLRequest) => {
  // These are all queries from `graphql/queries/publicQueries.graphql`.
  // For these queries we omit the Authorization header and execute them
  // as anonymous. This ensures that when authenticated users browse the
  // public site, they'll always see the same things as an anonymous
  // user would.
  // This is a workaround for the fact that
  // 1. the db is badly designed
  // 2. we can't create column permissions on a per-row basis in Hasura
  // 3. the public_listing view doesn't contain all necessary data
  // 4. creating public views for all of this information would be a lot of work and hard to maintain

  return publicOperations.includes(gqlRequest.operationName || '');
};

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;
const isServer = typeof window === 'undefined';
let token: string | undefined;

const getToken = async (ctx?: GetServerSidePropsContext) => {
  if (process.env.RUNNING_BUILD !== 'true') {
    const session = await getSession(ctx);
    token = session?.hasuraAccessToken;
  }
  return token;
};

const setTokenLink = (ctx?: GetServerSidePropsContext) =>
  setContext(async (gqlRequest, { headers }) => {
    await getToken(ctx);

    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: {
        ...(token && !isAlwaysAnonymousOperation(gqlRequest)
          ? { Authorization: `Bearer ${token}` }
          : undefined),
        // the headers passed from the query invocation may contain
        // overrides for the Authorization header so keep it after
        // the default Authorization header
        ...headers,
      },
    };
  });

const isServerError = (
  networkError: ErrorResponse['networkError'],
): networkError is ServerError =>
  !!networkError && networkError.name === 'ServerError';

const resetTokenLink = onError(({ networkError }) => {
  if (isServerError(networkError) && networkError.statusCode === 401) {
    // remove cached token on 401 from the server
    token = undefined;
  }
});

interface LimitOffsetArgs {
  offset?: number;
  limit?: number;
}

const offsetLimitReadFunction = <
  TExisting,
  TArgs extends LimitOffsetArgs = LimitOffsetArgs,
>() => ({
  read(existing: TExisting[], { args }: FieldFunctionOptions<TArgs>) {
    const { offset, limit } = {
      // Default to returning the entire cached list,
      // if offset and limit are not provided.
      offset: args?.offset ?? 0,
      limit: args?.limit ?? existing?.length ?? 0,
    };
    return existing && existing.slice(offset, offset + limit);
  },
});

function createApolloClient(ctx?: GetServerSidePropsContext) {
  const httpLink = new HttpLink({
    uri: NEXT_PUBLIC_API_URL,
    credentials: 'include',
  });

  const authHttpLink = setTokenLink(ctx)
    .concat(resetTokenLink)
    .concat(httpLink);

  const wsLink = new GraphQLWsLink(
    createClient({
      url: NEXT_PUBLIC_API_URL_WS,
      ...(isServer ? { webSocketImpl: Websocket } : undefined),
      connectionParams: async () => {
        const authToken = await getToken(ctx);
        return {
          headers: {
            ...(authToken
              ? { Authorization: `Bearer ${authToken}` }
              : undefined),
          },
        };
      },
    }),
  );

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authHttpLink,
  );

  const typePolicies: StrictTypedTypePolicies = {
    history: {
      keyFields: ['event_id'],
    },
    listing_watch: {
      keyFields: ['access_token'],
    },
    listing_watch_subscriber: {
      keyFields: ['access_token'],
      fields: {
        listing_watches: {
          merge(
            existing: { __ref: string }[] = [],
            incoming: { __ref: string }[] = [],
          ) {
            return [...existing, ...incoming];
          },
        },
      },
    },
    query_root: {
      queryType: true,
      fields: {
        history: offsetLimitPagination<HistoryAttributesFragment>(['where']),
        client: {
          ...offsetLimitPagination<ClientAttributesFragment>(['where']),
          ...offsetLimitReadFunction<ClientAttributesFragment>(),
        },
        // make `client_by_pk` queries look for client data cached by
        // other queries (specifically the `client` (list) query)
        client_by_pk: {
          read(existing, { args, toReference }) {
            return toReference({
              __typename: 'client',
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              id: args?.id,
            });
          },
        },
        diary_listing: {
          ...offsetLimitPagination<DiaryListingAttributesFragment>(['where']),
          ...offsetLimitReadFunction<DiaryListingAttributesFragment>(),
        },
        listing: {
          ...offsetLimitPagination<GetAllListingsAttributesFragment>(['where']),
          ...offsetLimitReadFunction<GetAllListingsAttributesFragment>(),
        },
        bill_of_sales: {
          keyArgs: ['where'],
        },
        real_estate_agency: {
          ...offsetLimitPagination<
            RealEstateAgencyAttributesFragment &
              RealEstateAgencyProtectedAttributesFragment
          >(['where']),
          ...offsetLimitReadFunction<
            RealEstateAgencyAttributesFragment &
              RealEstateAgencyProtectedAttributesFragment
          >(),
        },
      },
    },
  };

  return new ApolloClient({
    ssrMode: isServer,
    link: splitLink,
    cache: new InMemoryCache({
      typePolicies,
    }),
  });
}

/**
 * initializeApollo is used internally by useApollo, but can be used
 * in non-page components to obtain an apollo client.
 *
 * @param config         an optional site configuration object as obtained from `getConfig(context)`
 * @param initialState   an optional apollo initial state (normally not used when using )
 * @param ctx            an optional GetServerSidePropsContext object
 *
 * @returns              an apollo client
 */

export function initializeApollo(
  initialState?: NormalizedCacheObject,
  ctx?: GetServerSidePropsContext,
) {
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = apolloClient ?? createApolloClient(ctx);

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (isServer) {
    return _apolloClient;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }
  return _apolloClient;
}

/**
 * useApollo is more or less only used in _app.tsx to get a client to
 * pass to the ApolloProvider. It takes an initial state object
 * and returns a hydrated client. The hydrated client, which
 * already has a populated cache, can be passed to the ApolloProvider and
 * subsequently used by all useQuery etc hooks.
 *
 * @param initialState  the Apollo client state as obtained from
 *                      client.cache.extract() in server side / static rendering
 * @returns             a hydrated Apollo client
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApollo(initialState?: NormalizedCacheObject) {
  const store = useMemo(() => {
    return initializeApollo(initialState);
  }, [initialState]);
  return store;
}
