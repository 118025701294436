import { Integrations } from '@sentry/browser';
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENV =
  process.env.SENTRY_ENV ||
  process.env.NEXT_PUBLIC_SENTRY_ENV ||
  process.env.NODE_ENV;

Sentry.init({
  dsn: SENTRY_DSN,
  autoSessionTracking: false,
  environment: SENTRY_ENV,
  integrations: [
    new Integrations.Breadcrumbs({
      console: false,
    }),
  ],
});
