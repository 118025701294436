import {
  createContext,
  useState,
  FC,
  SetStateAction,
  Dispatch,
  useEffect,
} from 'react';
import isEqual from 'lodash.isequal';
import { Post, SiteNav } from '@/types';
import type { Overlap } from '@/components/frontend/ArticleLayout';

type UseState<T> = [T, Dispatch<SetStateAction<T>>];

const getFallbackNavItems = (locale?: string) => {
  if (locale === 'sv') {
    return [
      { ID: 873, children: null, title: 'K\u00f6p', url: '/osta' },
      {
        ID: 874,
        children: [
          {
            ID: 913,
            children: null,
            title: 'S\u00e4lj en fastighet',
            url: '/salj-en-fastighet',
          },
          {
            ID: 907,
            children: null,
            title: 'F\u00f6rs\u00e4ljningsuppdrag',
            url: '/forsaljningsuppdrag',
          },
          {
            ID: 905,
            children: null,
            title: 'Skogsfastigheter.fi tj\u00e4nster',
            url: '/skogsfastigheter-fi-tjanster',
          },
        ],
        title: 'S\u00e4lj',
        url: '',
      },
      {
        ID: 953,
        children: [
          {
            ID: 1003,
            children: null,
            title: 'Anvisningar f\u00f6r s\u00e4ljare',
            url: '/anvisningar-for-saljare',
          },
          {
            ID: 951,
            children: null,
            title: 'Anvisningar f\u00f6r k\u00f6pare',
            url: '/anvisningar-for-kopare',
          },
          {
            ID: 952,
            children: null,
            title: 'F\u00f6rdjupade anvisningar f\u00f6r k\u00f6pare',
            url: '/fordjupade-anvisningar-for-kopare',
          },
          {
            ID: 1018,
            children: null,
            title: 'Regler f\u00f6r anbudsf\u00f6rfarande',
            url: '/regler-for-anbudsforfarande',
          },
        ],
        title: 'Anvisningar och guider',
        url: '',
      },
      {
        ID: 989,
        children: null,
        title: 'Kontaktuppgifter',
        url: '/kontaktuppgifter',
      },
      {
        ID: 903,
        children: [
          {
            ID: 909,
            children: null,
            title: 'Skogsfastigheter.fi',
            url: '/information-om-tjansten',
          },
        ],
        title: 'Information om tj\u00e4nsten',
        url: '',
      },
    ];
  }

  if (locale === 'en') {
    return [
      { ID: 1157, children: null, title: 'Buy', url: '/osta' },
      {
        ID: 1158,
        children: [
          {
            ID: 1156,
            children: null,
            title: 'Sell property',
            url: '/sell-property',
          },
          {
            ID: 1183,
            children: null,
            title: 'Sales order',
            url: '/sell-property/sales-order',
          },
          {
            ID: 1168,
            children: null,
            title: 'Mets\u00e4tilat.fi services',
            url: '/sell-property/metsatilat-fi-services',
          },
        ],
        title: 'Sell',
        url: 'https://juu',
      },
      {
        ID: 1159,
        children: [
          {
            ID: 1153,
            children: null,
            title: 'Seller\u2019s instructions',
            url: '/sellers-instructions',
          },
          {
            ID: 1155,
            children: null,
            title: 'Buyer\u2019s instructions',
            url: '/buyers-instructions',
          },
          {
            ID: 1154,
            children: null,
            title: 'Buyer\u2019s advanced instructions',
            url: '/buyers-advanced-instructions',
          },
        ],
        title: 'Guides',
        url: '',
      },
      {
        ID: 1151,
        children: null,
        title: 'Contact information',
        url: '/contact-information',
      },
      {
        ID: 1160,
        children: [
          {
            ID: 1152,
            children: null,
            title: 'Mets\u00e4tilat.fi',
            url: '/information-about-the-service',
          },
        ],
        title: 'About service',
        url: '',
      },
    ];
  }

  return [
    { ID: 191, children: null, title: 'Osta', url: '/osta' },
    {
      ID: 200,
      children: [
        {
          ID: 197,
          children: null,
          title: 'Myy tila tai kiinteist\u00f6',
          url: '/myy',
        },
        {
          ID: 199,
          children: null,
          title: 'Myyntitoimeksianto',
          url: '/myy/myyntitoimeksianto',
        },
        {
          ID: 198,
          children: null,
          title: 'Mets\u00e4tilat.fi palvelut',
          url: '/myy/palvelut',
        },
      ],
      title: 'Myy',
      url: '',
    },
    {
      ID: 201,
      children: [
        {
          ID: 202,
          children: null,
          title: 'Ohjeet myyj\u00e4lle',
          url: '/ohjeet-myyjalle',
        },
        {
          ID: 315,
          children: [
            {
              ID: 382,
              children: null,
              title: 'Syvent\u00e4v\u00e4t ohjeet ostajalle',
              url: '/syventavat-ohjeet-ostajalle',
            },
          ],
          title: 'Ohjeet ostajalle',
          url: '/ohjeet-ostajalle',
        },
        {
          ID: 1017,
          children: null,
          title: 'Tarjousmenettelyn s\u00e4\u00e4nn\u00f6t',
          url: '/tarjousmenettelyn-saannot',
        },
      ],
      title: 'Ohjeet ja oppaat',
      url: '',
    },
    {
      ID: 193,
      children: null,
      title: 'Yhteystiedot',
      url: '/yhteystiedot',
      attr: 'contacts',
    },
    {
      ID: 203,
      children: [
        {
          ID: 196,
          children: null,
          title: 'Mets\u00e4tilat.fi',
          url: '/tietoa-palvelusta',
        },
        { ID: 194, children: null, title: 'Blogi', url: '/blogi' },
        { ID: 195, children: null, title: 'Uutiset', url: '/uutiset' },
      ],
      title: 'Tietoa palvelusta',
      url: '',
    },
  ];
};

interface AppProps {
  navigations: Partial<Omit<SiteNav, 'main_navigation'>> &
    Pick<SiteNav, 'main_navigation'>;
  entryTranslations: Post['translations'] | null;
  template: string | null;
  locale?: string;
}

const getAppInitialValue: (locale?: string) => UseState<AppProps> = (
  locale,
) => [
  {
    navigations: {
      // If WP is down or something is broken, use static items
      main_navigation: getFallbackNavItems(locale),
    },
    entryTranslations: null,
    template: null,
    locale: locale || 'fi',
  },
  (s: SetStateAction<AppProps>) => s,
];

const createAppState = ({
  navigations,
  locale,
  ...props
}: Partial<AppProps>): AppProps => {
  const initial = { ...getAppInitialValue(locale)[0], ...props };
  if (!navigations) {
    return initial;
  }
  return {
    ...initial,
    navigations: {
      ...initial.navigations,
      ...navigations,
    },
  };
};

export const AppContext = createContext(getAppInitialValue());

export const AppProvider: FC<Partial<AppProps>> = ({
  children,
  ...appProps
}) => {
  const [state, setState] = useState(
    appProps ? createAppState(appProps) : getAppInitialValue()[0],
  );

  useEffect(() => {
    if (appProps) {
      const updatedAppProps = createAppState(appProps);
      setState((s) => (isEqual(updatedAppProps, s) ? s : updatedAppProps));
    }
  }, [appProps]);

  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  );
};

/*
 * LayoutProvider functions a bit like <body class="">, but offers nicer API
 */

interface LayoutProps {
  overlap?: null | Overlap;
}

const layoutInitialValue: UseState<LayoutProps> = [
  {},
  (s: SetStateAction<LayoutProps>) => s,
];

export const LayoutContext = createContext(layoutInitialValue);

export const LayoutProvider: FC = ({ children }) => {
  const [state, setState] = useState(layoutInitialValue[0]);

  return (
    <LayoutContext.Provider value={[state, setState]}>
      {children}
    </LayoutContext.Provider>
  );
};
